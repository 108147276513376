/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { palette } from '@/styles/JJKeller/palette';
import { TextField } from '@/styles/JJKeller/TextField';
import { typography } from '@/styles/JJKeller/type';
import { Accordion } from '@/styles/JJKeller/Accordion';
import { shape } from '@/styles/JJKeller/shape';
import { ThemeManifestTheme } from '@/styles/manifest';
import { Button } from '@/styles/JJKeller/Button';
import { Checkbox } from '@/styles/JJKeller/Checkbox';
import { Icon } from '@/styles/JJKeller/Icon';
import { Radio } from '@/styles/JJKeller/Radio';
import { Link } from '@/styles/JJKeller/Link';
import { breakpoints } from '@/styles/JJKeller/breakpoints';
import { fonts } from '@/styles/JJKeller/fonts';
import { Alert } from '@/styles/JJKeller/Alert';
import { CssBaseline } from '@/styles/JJKeller/CssBaseline';
import { Divider } from '@/styles/JJKeller/Divider';

export const JJKellerTheme: ThemeManifestTheme = {
	inheritFrom: 'Default',
	components: [
		CssBaseline,
		fonts,
		breakpoints,
		palette,
		typography,
		shape,
		TextField,
		Button,
		Checkbox,
		Icon,
		Radio,
		Link,
		Accordion,
		Alert,
		Divider,
	],
};
