import { useProduct } from '@/data/Content/Product';
import { IncomingContent } from '@/data/types/IncomingContent';
import Head from 'next/head';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { FC } from 'react';

const Tokens: string[] = [
    'CategoryToken',
    'ProductToken',
    'StaticPagesToken',
];

const NonCMCPageType: string[] = [
    'NewsArticlePage',
    'NonPdpLeadGenerationPage',
];

export type pageData = {
    pageData: IncomingContent
}

export const Canonical: FC<pageData> = ({ pageData }) => {
    const router = useNextRouter();
    const hostName =
        process.env.NODE_ENV === 'development'
            ? 'localhost'
            : typeof window !== 'undefined'
                ? location?.hostname
                : 'undefined';

    const { product } = useProduct({ id: pageData?.tokenExternalValue });
    const { product: parentProduct } = useProduct({ id: product?.parentCatalogEntryID, isCEId: true });
    const tokenName = pageData.tokenName;
    const pageType = pageData.page.type;
    const requiresCanonical = Tokens.includes(tokenName) || NonCMCPageType.includes(pageType);
    let canonicalURL;

    const productUrl = () => {
        if (pageData.page?.type === 'ItemPage') {
            return parentProduct?.seo.href;
        } else {
            return product?.seo.href;
        }
    };

    const staticUrl = () => {
        const pageSeo = pageData?.redirect ? pageData.redirect : pageData.identifier;
        if (pageSeo) {
            if (pageSeo === 'home') {
                return '/';
            } else {
                return pageSeo.startsWith('/') ? pageSeo : `/${pageSeo}`;
            }
        } else {
            return '';
        }
    };

    switch (tokenName) {
        case 'ProductToken':
            canonicalURL = productUrl();
            break;
        case 'CategoryToken':
        case 'StaticPagesToken':
            canonicalURL = staticUrl();
            break;
        case '':
            canonicalURL = router.asPath;
            break;
    }

    return (
        requiresCanonical && canonicalURL ? <Head>
            <link rel="canonical" href={`https://${hostName}${canonicalURL}`} />
        </Head> : null
    );
};
