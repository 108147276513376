/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { buttonVariantsStyles } from '@/styles/JJKeller/Button/variantsStyles';
import { buttonVariants } from '@/styles/JJKeller/Button/variants';
import { getStyleOverrides } from '@/utils/getStyleOverrides';
import { ThemeOptions } from '@mui/material/styles';

export const Button: ThemeOptions = {
	components: {
		MuiButtonBase: {
			defaultProps: {
				// The props to change the default for.
				disableRipple: false,
			},
		},
		MuiButton: {
			variants: buttonVariants,
			styleOverrides: {
				root: getStyleOverrides({
					styles: {
						py: 1.5,
						px: 2,
						borderRadius: 2,
						minWidth: 'auto',
						fontWeight: 600,
						boxShadow: 'none',
						'&.Mui-disabled': {
							color: 'button.disabledText',
						},
						'.MuiButton-iconSizeMedium': {
							marginLeft: '3px',
						},
						transition: 'all 0.2s ease-out allow-discrete',
					},
					variants: buttonVariantsStyles,
				}),
				contained: getStyleOverrides({
					styles: {
						backgroundColor: 'button.primary',
						color: 'button.contrastText',
						borderRadius: '10px',
						'&:focus': {
							backgroundColor: 'button.primaryFocus',
						},
						'&:hover': {
							opacity: '0.8',
							backgroundColor: 'button.primary',
							boxShadow: 'none',
						},
						'&.Mui-disabled': {
							backgroundColor: 'button.disabled',
						},
					},
				}),
				outlined: getStyleOverrides({
					styles: {
						backgroundColor: 'button.secondary',
						color: 'button.primary',
						borderColor: 'button.primary',
						'&:hover': {
							backgroundColor: 'button.secondaryHover',
							color: 'button.primary',
							borderColor: 'button.primary',
						},
					},
				}),
				text: getStyleOverrides({
					styles: {
						boxShadow: 'none',
						minWidth: 'unset',
						color: 'primary.main',
						'&:hover': {
							color: 'primary.dark',
						},
					},
				}),
			},
		},
	},
};
declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		inline: true;
		'blue-outlined': true;
		'blue-underline': true;
		'blue-no-outline': true;
		'blue-link': true;
		'blue-contained': true;
		'blue-search': true;
		'burgundy-contained': true;
		'checkout-contained': true;
		'burgundy-outlined': true;
		'burgundy-no-outline': true;
	}
}
