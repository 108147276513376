/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const EMSTYPE_LOCAL = 'local';
export const EMPTY_STRING = '';

export const MARKETING_SPOT_DATA_TYPE = {
	CATALOG_ENTRY_ID: 'CatalogEntryId',
	CATALOG_ENTRY: 'CatalogEntry',
	CONTENT: 'MarketingContent',
	CATALOG_GROUP_ID: 'CatalogGroupId',
	CATEGORY: 'Category',
	DM_NVP: 'DM_NVP',
};

export const NAVIGATION_MARKETING_SPOT_SUFFIX = '_Nav';
export const NAVIGATION_ADDITIONAL_LINKS_MARKETING_SPOT_SUFFIX = '_Navlink';

export const CONTENT_FORMAT_TEXT = 'Text';
export const CONTENT_FORMAT_WEB = 'web';
export const CONTENT_FORMAT_FILE = 'File';
export const CONTENT_MIME_TYPE_IMAGE = 'image';

export const IS_ADV_ELIGIBLE_USER = 'isAdvEligibleUser';
export const IS_ADVANTAGE_USER = 'isAdvEnrolledUser';
export const IS_TRIAL_USER = 'isAdvTrialUser';

export const CATEGORY_DISPLAY = 'CategoryDisplay';
export const PRODUCT_DISPLAY = 'ProductDisplay';
export const TOP_CATEGORIES_DISPLAY = 'TopCategoriesDisplay';
export const DISPLAY_SEQ = 'displaySequence';

export const HEADER_LOGO = 'global_header_logo';
export const HEADER_LOGO_PUNCHOUT = 'global_header_logo_punchout';
export const HEADER_COPY = 'global_header_copy';
export const HEADER_TOP_NAV = 'global_header_top_nav';
export const HEADER_TOP_NAV_DROPDOWN = 'global_header_top_dropdown';
export const HEADER_ACCOUNT_LINKS = 'global_header_account_links';
export const HEADER_MOBILE_NAV = 'global_header_mobile_nav';
export const HEADER_GLOBAL_BANNER = 'global_messaging_top';
export const FOOTER_LOGO = 'global_footer_logo';
export const FOOTER_EMAIL_COPY = 'global_footer_email_copy';
export const FOOTER_COMPANY_LINKS = 'global_footer_company_links';
export const FOOTER_SOCIAL_LINKS = 'global_footer_social_links';
export const FOOTER_POLICY = 'global_policy_links';
export const FOOTER_COPYRIGHT = 'global_copyright';
export const FOOTER_CHECKOUT_SATISFACTION_COPY = 'checkout_footer_satisfaction_copy';
export const FOOTER_CHECKOUT_NEED_HELP = 'checkout_footer_need_help';
export const FOOTER_CHECKOUT_TOOLTIP = 'checkout_footer_tooltip';
export const FOOTER_CHECKOUT_PRIVACY_POLICY = 'checkout_privacy_policy';
export const FOOTER_BRAND_STATEMENT = 'global_footer_brand-statement';
export const HEADER_CHECKOUT_CUSTOM_LOGO = 'checkout_custom_logo';
export const HEADER_CHECKOUT_BACK_TO_CART = 'checkout_back_to_cart';
export const CART_SUMMARY_BOTTOM_2 = 'cart_summary_bottom_2';
export const CART_SUMMARY_BOTTOM_1 = 'cart_summary_bottom_1';
export const CART_EPROCUREMENT_CONTENT_PUNCHOUT = 'cart_e-procurement_content_punchout';
export const CART_SHIP_TAX = 'cart_ship_tax_cost';
export const TAX_TOOLTIP = 'order_summary_tax_tooltip';
export const CHECKOUT_SHIPPING_INFO = 'checkout_shipping_copy';
export const ADDRESS_BOOK_DESC = 'account_address-book_description';
export const HERO_404 = '404_hero';
export const HEADER_404 = '404_header';
export const SUBHEADER_404 = '404_subheader';
export const HELPFUL_LINKS_404 = '404_helpful_links';
export const HOMEPAGE_404 = '404_homepage_button';
export const SHOP_PRODUCTS_404 = '404_shop_products_button';
export const CUSTOMER_SERVICE_404 = '404_customer_service_button';
export const PRO_SERVICES_404 = '404_pro_services_button';
export const CATEGORY_OPTIONAL_BANNER = 'category_optional_banner';
export const ORDER_STATUS_TOOLTIP = 'order_status_tooltip';
export const ACCOUNT_REGISTRATION_TERMS = 'global_account_registration_terms_conditions';
export const ACCOUNT_REPRESENTATIVE = 'account_representative';
export const GLOBAL_ACCOUNT_SESSION_TIMEOUT_SUBHEADING =
	'global_account_session_timeout_subheading';
export const PLP_PRODUCT_TILE_ADVANTAGE_MEMBERSHIP = 'JJKellerAdvantagePLP_Item_Espot';
export const JJK_PLP_BOTTOM_TEXT = 'JJK_PLP_Bottom_Text ';
export const CHECKOUT_AUTO_RENEWAL_INFO = 'checkout_auto_renewal_info';
export const JJKELLERFASTSHIPPLP_ITEM_ESPOT = 'JJKellerFastShipPLP_Item_Espot';
export const CUSTOMER_SEGMENT_VALIDATION_ESPOT = 'CustomerSegmentValidation_Espot';
export const ORDER_CONFIRMATION_HEADER_ESPOT = 'order_confirmation_info';
export const REGISTRATION_HEADER_ESPOT = 'registration_header_top_nav';
export const PLP_FAST_SHIP_TOOLTIP_ESPOT = 'plp_fast-ship_tooltip';
export const PLP_ADVANTAGE_TOOLTIP = 'plp_advantage_tooltip';
export const ACCOUNT_CUSTOM_TOP = 'account_custom_top';
export const ACCOUNT_CUSTOM_BOTTOM = 'account_custom_bottom';
export const LEAD_GEN_FORM_INFO = 'lead-gen-form-info';
export const ACCOUNT_ADVANTAGE_MEMBERSHIP = 'account_advantage_membership';
export const ACCOUNT_ADV_MEMBER_LINKS = 'account_adv_member_links';
export const ACCOUNT_ADV_DISCOUNTS = 'account_adv_discounts';
export const EVENTS_HERO = 'events_hero';
export const EVENTS_ABOUT = 'events_about';
export const EMAIL_PREF_IFRAME = 'email_preferences_iframe';
export const ACCOUNT_ADVANTAGE_EXPERT_HELP = 'advantage_member_expert_help';
export const ADVANTAGE_HEADER_LOGO = 'ES_Header_Custom_Msg';
export const ADVANTAGE_HEADER_SUBMENU = 'ES_Header_Advantage';
export const ADVANTAGE_TRIAL_PRODUCT_PART_NUMBER = 'Advantage_Trial_Product';
export const ADVANTAGE_MEMBER_PRODUCT_PART_NUMBER = 'Advantage_Member_Product';
export const ADVANTAGE_MEMBER_PRODUCT = 'Advantage_Program-Full_Info';
export const ADVANTAGE_TRIAL_PRODUCT = 'Advantage_Program-Trial_Info';
export const ADVANTAGE_FORM_VIDEO = 'Advantage_Form_Video';
export const ADVANTAGE_FORM_GUIDE = 'Advantage_Form_Guide';
export const ACCOUNT_ADVANTAGE_MEMBERSHIP_CONTENT = 'account_advantage_membership_content';
export const ADVANTAGE_MEMBER_SDS = 'advantage_member_sds';
export const ADVANTAGE_MEMBER_SDS_FAQ = 'advantage_member_sds_faq';
export const ADVANTAGE_MEMBER_HAZARD_SOLUTIONS = 'advantage_member_sds_hazard_solutions';
export const ARTICLE_BOTTOM = 'article_bottom';
export const ADVANTAGE_DATA_TABLE_FOOTER = 'advantage_resources_table_footer';
export const ADVANTAGE_DATA_TABLE_HEADER = 'advantage_resources_table_header';
export const PDP_SIDE_ESPOT = 'PDP_Side_Espot';
export const REORDER_SECURITY_SEALS_LEFT = 'reorder_security_seals_left';
export const REORDER_SECURITY_SEALS_RIGHT = 'reorder_security_seals_right';
export const REORDER_SAFETY_SIGN_LEFT = 'reorder_safety_sign_left';
export const REORDER_SAFETY_SIGN_RIGHT = 'reorder_safety_sign_right';
export const RELATED_SEARCHES_INFO = 'related_searches_info';
export const ADVANTAGE_BANNER_INFO = 'advantage-banner-info';
export const SUBJECT_ID_INFO = 'subject-id-info';
export const LOGON_FORM_SIGN_IN = 'logon_form_sign_in';
export const LOGON_FORM_JJK_SITES_LINKS = 'ES_JJK_Sites_Links_Logon_Page';
export const SECURITY_SEALS_FINDER_HERO = 'security-seals-finder_Finder-Header';
export const FLIP_PLACARD_FINDER_HERO = 'flip-placard-finder_Finder-Header';
export const COMMON_FINDER_FOOTER = 'common_Finder-Footer';
export const CUSTOMER_SERVICE_SIDENAV = 'Customer-Service_SideNav';
export const ADVANTAGE_MEMBERS_MESSAGE = 'advantage_members-save_message';
export const POSTER_COMPLIANCE_LEFT = 'poster_compliance_left';
export const POSTER_COMPLIANCE_RIGHT = 'poster_compliance_right';
export const ACCOUNT_CUSTOMER_BANNER = 'account_customer_banner';
export const LAYOUT_NAME_ACCOUNT = 'Account';
