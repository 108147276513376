/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { useNotifications } from '@/data/Content/Notifications';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { selfFetcher, selfUpdater } from '@/data/Content/_Person';
import { useLocalization } from '@/data/Localization';
import { useSettings } from '@/data/Settings';
import { contextAttribute } from '@/data/types/Person';
import { DATA_KEY_PERSON } from '@/data/constants/dataKey';
import { EditableAddress } from '@/data/types/Address';
import { TransactionErrorResponse } from '@/data/types/Basic';
import { Person, PersonContact } from '@/data/types/Person';
import { personalContactInfoMutatorKeyMatcher } from '@/data/utils/mutatorKeyMatchers/personalContactInfoMutatorKeyMatcher';
import { processError } from '@/data/utils/processError';
import { FormEvent, KeyboardEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { ErrorType } from '@/data/types/Error';

export type EditablePersonInfo = Omit<EditableAddress, 'addressType' | 'nickName'> & {
	parentOrgId?: string;
	profileType?: string;
};
export type ChangePasswordValues = {
	xcred_logonPasswordOld: string;
	logonPassword: string;
	xcred_logonPasswordVerify: string;
	x_logonPasswordOld?: string;
	x_isVerificationRequired?: string;
	x_editPassword?: string;
	logonId?: string;
};

type attributes =
	| {
			[key: string]: string;
	  }
	| undefined;

const dataMap = (data?: Person): EditablePersonInfo => {
	const {
		firstName = '',
		lastName = '',
		email1 = '',
		addressLine = ['', ''],
		city = '',
		country = '',
		state = '',
		zipCode = '',
		phone1 = '',
		orgizationId: parentOrgId,
		profileType = '',
		organizationName = '',
	} = data ?? {};
	return {
		firstName,
		lastName,
		email1,
		addressLine1: addressLine[0] ?? '',
		addressLine2: addressLine[1] ?? '',
		city,
		country,
		state,
		zipCode,
		phone1,
		parentOrgId,
		profileType,
		organizationName,
	};
};

const attributeDataMap = (data?: contextAttribute[] | undefined): attributes =>
	data?.reduce((o, c) => ({ ...o, [c.attributeName]: c.attributeValue[0].value[0] }), {});

export const usePersonInfo = () => {
	const { settings } = useSettings();
	const params = useExtraRequestParameters();
	const personalInformationNLS = useLocalization('PersonalInformation');
	const { showSuccessMessage, notifyError } = useNotifications();
	const [editing, setEditing] = useState(false);
	const [changePassword, setChangePassword] = useState(false);
	const [contactFormActive, setContactFormActive] = useState<boolean>(false);
	const [passwordFormActive, setPasswordFormActive] = useState<boolean>(false);
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

	const [passwordErrorMessage, setPasswordErrorMessage] = useState<
		TransactionErrorResponse | ErrorType
	>();
	const {
		data,
		error: personInfoError,
		mutate: mutatePersonInfo,
	} = useSWR(
		settings?.storeId
			? [
					{
						storeId: settings.storeId,
					},
					DATA_KEY_PERSON,
			  ]
			: null,
		async ([props]) => selfFetcher(true)(props.storeId, undefined, params)
	);

	const edit = useCallback(() => {
		setEditing(true);
	}, []);

	const cancelEdit = useCallback(() => {
		setEditing(false);
	}, []);

	const toggleDrawer =
		(open: boolean, contactFormBool: boolean, passwordFormBool: boolean) =>
		(event: FormEvent<HTMLFormElement> | React.KeyboardEvent | React.MouseEvent) => {
			console.log('===', open);
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}
			setContactFormActive(contactFormBool);
			setPasswordFormActive(passwordFormBool);
			setDrawerOpen(open);
		};

	const savePersonInfo = useCallback(
		async (info: EditablePersonInfo, event: any) => {
			const { addressLine1, addressLine2, ..._address } = info;
			const data = { addressLine: [addressLine1, addressLine2 ?? ''], ..._address };
			const storeId = settings?.storeId ?? '';
			try {
				await selfUpdater(true)(storeId, undefined, data, params);
				mutate(personalContactInfoMutatorKeyMatcher(''), undefined);
				setEditing(false);
				showSuccessMessage(personalInformationNLS.UpdateSuccessful.t());
				toggleDrawer(false, false, false)(event);
			} catch (e) {
				notifyError(processError(e as TransactionErrorResponse));
			}
		},
		[
			notifyError,
			personalInformationNLS.UpdateSuccessful,
			settings?.storeId,
			showSuccessMessage,
			params,
		]
	);

	const closePasswordDialog = useCallback(() => {
		setChangePassword(false);
	}, []);

	const openPasswordDialog = useCallback(() => {
		setChangePassword(true);
	}, []);

	const updatePassword = useCallback(
		async (value: ChangePasswordValues, event: any) => {
			const data = { ...value };
			const storeId = settings?.storeId ?? '';
			try {
				await selfUpdater(true)(storeId, undefined, data, params);
				setChangePassword(false);
				showSuccessMessage(personalInformationNLS.UpdateSuccessful.t());
				setPasswordErrorMessage(undefined);
				toggleDrawer(false, false, false)(event);
			} catch (e) {
				setPasswordErrorMessage(processError(e as TransactionErrorResponse));
				// notifyError(processError(e as TransactionErrorResponse));
			}
		},
		[personalInformationNLS.UpdateSuccessful, settings?.storeId, showSuccessMessage, params]
	);

	const { personInfo, primaryAddress } = useMemo(() => {
		const personInfo = dataMap(data);
		const { nickName, addressLine, addressType, addressId } = data ?? {};
		const primaryAddress = nickName
			? ({
					...personInfo,
					addressId,
					addressLine,
					nickName,
					addressType,
					primary: 'true',
			  } as PersonContact)
			: undefined;
		return { personInfo, primaryAddress };
	}, [data]);

	const { contextAttributes } = useMemo(() => {
		const { contextAttribute } = data ?? {};
		const contextAttributes = attributeDataMap(contextAttribute);
		return { contextAttributes };
	}, [data]);

	const isB2bUser = useMemo(() => personInfo?.profileType === 'Business', [personInfo]);

	const parentOrgId = useMemo(() => personInfo?.parentOrgId || '', [personInfo]);

	return {
		personInfo,
		isB2bUser,
		parentOrgId,
		primaryAddress,
		contextAttributes,
		mutatePersonInfo,
		personInfoError,
		editing,
		edit,
		cancelEdit,
		savePersonInfo,
		updatePassword,
		closePasswordDialog,
		openPasswordDialog,
		changePassword,
		passwordErrorMessage,
		setPasswordErrorMessage,
		toggleDrawer,
		drawerOpen,
		passwordFormActive,
		contactFormActive,
	};
};
