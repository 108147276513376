import { SxProps, Theme } from '@mui/material';

export const cartContainerSX: SxProps<Theme> = {
	padding: 2,
	minWidth: '20vw',
	maxWidth: '375px',
	backgroundColor: 'background.lightgray',
	height: '100%',
	overflow: 'auto',

	'& .MuiButton-root': {
		fontSize: '18px',
		padding: '8px 16px !important',
		borderRadius: '4px',
	},
};
