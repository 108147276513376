/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

export const DATA_KEY_PAYMENT_INFO = 'PAYMENT_INFO';
export const DATA_KEY_SHIPPING_INFO = 'USABLE_SHIPPING_INFO';
export const DATA_KEY_PERSON = 'person';
export const DATA_KEY_ORDER_BY_ID = 'order-by-id';
export const DATA_KEY_PRODUCT_BY_ID = 'product-by-id';
export const DATA_KEY_ARTICLE_BY_ID = 'article-by-id';
export const DATA_KEY_NEWS_WIDGET_LIST_BY_ID = 'article-by-id';
export const DATA_KEY_EVENTS_WIDGET_LIST_BY_ID = 'events-by-id';
export const DATA_KEY_EVENTS_WIDGET_LIST_BY_TAG = 'events-by-tag';
export const DATA_KEY_ORDERS_BY_STATUS = 'orders-by-status';
export const DATA_KEY_WISH_LIST = 'wish-list';
export const DATA_KEY_CHECKOUT_PROFILES = 'CHECKOUT_PROFILES';
export const DATA_KEY_CONTRACT = 'contract';
export const DATA_KEY_ORGANIZATION_DESCRIPTIONS = 'organization-descriptions';
export const DATA_KEY_USER = 'User';
export const DATA_KEY_JJKUSER = 'JJKUser';
export const DATA_KEY_CART = 'cart';
export const DATA_KEY_REQUISITION_LIST = 'RequisitionList';
export const DATA_KEY_ORGANIZATION_DETAILS = 'organization-details';
export const DATA_KEY_VIEW_UPLOAD_LOG = 'ViewUploadLog';
export const DATA_KEY_SMARTY_KEY = 'SMARTY_EMBEDDED_KEY';
export const DATA_KEY_OKTA_ISSUER_ID = 'OKTA_ISSUER_ID';
export const DATA_KEY_OKTA_CLIENT_ID = 'OKTA_CLIENT_ID';
export const DATA_KEY_APP_DYNAMICS_KEY = 'APP_KEY_ID';
export const COOKIES_KEY = 'COOKIE_KEY_ID';
export const DATA_KEY_EVENT_URL = 'EVENT_URL';
export const DATA_KEY_PREVIOUS_URL = 'PREVIOUS_URL';
export const DATA_KEY_CUSTOM_SIGNS_HOSTNAME = 'CUSTOM_SIGNS_HOSTNAME';
export const DATA_KEY_IMPRINT_SEAL_ATRRIBUTES_BY_PARTNUMBER =
	'imprint-seal-attributes-by-partnumber';
export const DATA_KEY_EDITORAIL_TOPIC_LIST = 'EditorialTopicList';
export const DATA_KEY_EDITORAIL_TOPIC_SEARCH = 'EditorialTopicSearch';
export const DATA_KEY_EDITORAIL_TOPIC_ARTICLE = 'EditorialTopicArticle';
export const DATA_KEY_SIMPLE_NEWS_WIDGET = 'simple-news-list';
export const DATA_KEY_PRODUCT = 'PRODUCT';
